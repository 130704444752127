/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import React from "react"
import ReactDom from "react-dom/client" 
 import About from "./Pages/About.jsx"; 
 import Blog from "./Pages/Blog.jsx"; 
 import Location from "./Pages/Location.jsx";
 import Offer from "./Pages/Offer.jsx"; 
 import Search from "./Pages/Search.jsx"; 
 import Contact from "./Pages/Contact.jsx";
 import Deliveryagent from "./Pages/Deliveryagent.jsx";
 import StoreMerchant from "./Pages/StoreMerchant.jsx"; 
 import Aboutusection from "./views/About/Aboutusection.jsx"; 
 import Jobs from "./Pages/Jobs.jsx"; 
 import Support from "./Pages/Support.jsx";
 import News from "./Pages/News.jsx";
 import Terms from "./Pages/Terms.jsx";
 import Privacy from "./Pages/Privacy.jsx";
 import Home from "./Pages/Home.jsx"; 
import Agentsections from "./views/Join/Agentsections.jsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Merchantsections from "./views/Join/Merchantsections.jsx";
import Blog1 from "./views/Blogs/Blog1.jsx";
import Blog2 from "./views/Blogs/Blog2.jsx";
import Blog3 from "./views/Blogs/Blog3.jsx";
import Quickapply from "./views/Home/Quickapply.jsx";
import Startsip from "./views/Home/Startsip.jsx";
import Faq from "./Pages/Faq.jsx";
import Services from "./Pages/Services.jsx";

  
const router = createBrowserRouter([ 
  { 
    path: '/',
    element: <Home /> 
  }, 
  { 
    path: '/About',
    element: <About /> 
  }, 
  { 
    path: '/Home',
    element: <Home /> 
  }, 
 
  { 
    path: '/Quickapply',
    element: <Quickapply /> 
  },
  { 
    path: '/Startsip',
    element: <Startsip /> 
  },
   
  { 
    path: '/Blog',
    element: <Blog /> 
  },
  { 
    path: '/Faq',
    element: <Faq /> 
  },
  { 
    path: '/Services',
    element: <Services /> 
  },
  { 
    path: '/Location',
    element: <Location /> 
  },

  { 
    path: '/Offer',
    element: <Offer /> 
  },
 

  { 
    path: '/Search',
    element: <Search /> 
  },
 
  { 
    path: '/Aboutusection',
    element: <Aboutusection /> 
  }, 
  { 
    path: '/Contact',
    element: <Contact /> 
  }, 
  { 
    path: '/StoreMerchant',
    element: <StoreMerchant /> 
  }, 
  { 
    path: '/Deliveryagent',
    element: <Deliveryagent /> 
  } ,
  { 
    path: '/Jobs',
    element: <Jobs /> 
  } ,
  { 
    path: '/Support',
    element: <Support /> 
  } ,
  { 
    path: '/News',
    element: <News /> 
  } ,
  { 
    path: '/Terms',
    element: <Terms /> 
  } ,
  { 
    path: '/Privacy',
    element: <Privacy /> 
  } ,
  { 
    path: '/Agentsections',
    element: <Agentsections /> 
  } ,
  { 
    path: '/Merchantsections',
    element: <Merchantsections /> 
  } ,
  { 
    path: '/Blog1',
    element: <Blog1 /> 
  } ,
  { 
    path: '/Blog2',
    element: <Blog2 /> 
  } ,
  { 
    path: '/Blog3',
    element: <Blog3 /> 
  } 
 
]);
 

ReactDom.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  <RouterProvider router={router} />
  </React.StrictMode>
)

export default router;