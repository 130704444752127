/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import React from "react";
import { 
  Button, 
  ListItem,
  ListItemPrefix,
  ListItemSuffix, 
} from "@material-tailwind/react";
  
export default function Joinsection() {
  return (
   <> 
   
   <section class="mb-[42px] text-gray-600 body-font">
   <div class="mx-auto">
      <div class="mt-6">
      <h3 class="text-center text-2xl font-bold sm:text-2xl"> Open Demat & Mutual Fund Acount  </h3>
        </div>
      </div>
  <div class="container px-4 mt-6 mx-auto flex flex-wrap">
 
      <div class="flex flex-wrap -mx-2">


      <br></br>
        <div class="px-2 w-1/2">
          <div class="flex flex-wrap w-full bg-gradient-to-t from-black  bg-yellow-600 bg-opacity-40 sm:py-24 py-16 sm:px-10 px-6 relative">
          <img
          class="w-full object-cover h-full object-center block opacity-25 absolute inset-0" src="https://ik.imagekit.io/moneyvision/Banner/banks/bannernew/1.webp?updatedAt=1714549363208"
          alt="pic"
        />
            <div class="text-center relative z-5 w-full">
              <h2 class="text-[15px] text-white font-bold title-font mb-2"> ICICI Direct  </h2> 
              <a href="https://secure.icicidirect.com/customer/accountopening?rmcode=PRAQ1172" >  <Button className="bg-green-500 mt-3" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700  hover:text-white  focus:text-white">
         <a target_blank href="https://secure.icicidirect.com/customer/accountopening?rmcode=PRAQ1172" className="text-[10px] mr-[2px]" > Apply Now     </a>

  <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix>
        </ListItem> 
        </Button></a>
            </div>
          </div>
        </div>

 

        <div class="px-2 w-1/2">
        <div class="flex flex-wrap w-full bg-gradient-to-t from-black  bg-green-600 bg-opacity-30 sm:py-24 py-16 sm:px-10 px-6 relative">
          <img
          class="w-full object-cover h-full object-center block opacity-20 absolute inset-0" src="https://ik.imagekit.io/moneyvision/Banner/banks/bannernew/2.webp?updatedAt=1714549363136"
          alt="pic"
        />
            <div class="text-center relative z-5 w-full">
              <h2 class="text-[15px] text-white font-bold title-font mb-2"> PPFAS Self Invest </h2>
              
              <a href="https://selfinvest.ppfas.com/investor/regular/ARN-104374/E134839" >  <Button className="bg-green-500  mt-3" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700  hover:text-white  focus:text-white">
         <a href="https://selfinvest.ppfas.com/investor/regular/ARN-104374/E134839"  
         target_blank className="text-[9px] mr-[2px]" > Apply Now  </a>

  <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix>
        </ListItem> 
        </Button></a>
            </div>
          </div>
        </div>


 
        <div class="px-2 w-1/2">
        <div class="flex flex-wrap w-full bg-gradient-to-t from-black  bg-yellow-500 bg-opacity-30 sm:py-24 py-16 sm:px-10 px-6 relative">
          <img
          class="w-full object-cover h-full object-center block opacity-20 absolute inset-0" src="https://ik.imagekit.io/moneyvision/Banner/banks/bannernew/4.webp?updatedAt=1714549363204"
          alt="pic"
        />
            <div class="text-center relative z-5 w-full">
              <h2 class="text-[15px] text-white font-bold title-font mb-2">  Motilaloswal DEMAT & TRADING  </h2>
              
              <a href="https://ekyc.motilaloswal.com/Partner/?diyid=a626e8bc-b393-45ef-80d9-1d801c31167f" >  <Button className="bg-green-500 mt-3" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700  hover:text-white  focus:text-white">
         <a href="https://ekyc.motilaloswal.com/Partner/?diyid=a626e8bc-b393-45ef-80d9-1d801c31167f"  target_blank className="text-[9px] mr-[2px]" > Apply Now  </a>

  <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix>
        </ListItem> 
        </Button></a>
            </div>
          </div>
        </div>
  
  
       {/*  <div class="px-2 w-1/2">
        <div class="flex flex-wrap w-full bg-gradient-to-t from-black  bg-yellow-400 bg-opacity-30 sm:py-24 py-16 sm:px-10 px-6 relative">
          <img
          class="w-full object-cover h-full object-center block opacity-20 absolute inset-0" src="https://ik.imagekit.io/moneyvision/Banner/banks/bannernew/3.webp?updatedAt=1714549363102"
          alt="pic"
        />
            <div class="text-center relative z-5 w-full">
              <h2 class="text-[15px] text-white font-bold title-font mb-2"> LIC Mutual Fund Account Apply  </h2>
              
              <a href="https://online.licmf.com/MyProfile/DistributorLink.aspx?Agent=ARN-104374&Euin=E134839" >  <Button className="bg-green-500 mt-3" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700  hover:text-white  focus:text-white">
         <a href="https://online.licmf.com/MyProfile/DistributorLink.aspx?Agent=ARN-104374&Euin=E134839"  target_blank className="text-[9px] mr-[2px]" > Apply Now  </a>

  <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix>
        </ListItem> 
        </Button></a>
            </div>
          </div>
        </div> */}


        <div class="px-2 w-1/2">
        <div class="flex flex-wrap w-full bg-gradient-to-t from-black  bg-yellow-400 bg-opacity-30 sm:py-24 py-16 sm:px-10 px-6 relative">
          <img
          class="w-full object-cover h-full object-center block opacity-20 absolute inset-0" src="https://ik.imagekit.io/hickter/Hickter_Projects/Money_Vision/ag.png?updatedAt=1714751147080"
          alt="pic"
        />
            <div class="text-center relative z-5 w-full">
              <h2 class="text-[15px] text-white font-bold title-font mb-2"> Angel Broking   </h2>
              
              <a href="https://angel-one.onelink.me/Wjgr/cvmm5r2h" >  <Button className="bg-green-500 mt-3" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700  hover:text-white  focus:text-white">
         <a href="https://angel-one.onelink.me/Wjgr/cvmm5r2h"  target_blank className="text-[9px] mr-[2px]" > Apply Now  </a>

  <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix>
        </ListItem> 
        </Button></a>
            </div>
          </div>
        </div>


       <div class="px-2 w-1/2">
        <div class="flex flex-wrap w-full bg-gradient-to-t from-black  bg-yellow-400 bg-opacity-30 sm:py-24 py-16 sm:px-10 px-6 relative">
          <img
          class="w-full object-cover h-full object-center block opacity-20 absolute inset-0" src="https://ik.imagekit.io/hickter/Hickter_Projects/Money_Vision/dcb.png?updatedAt=1714751375559"
          alt="pic"
        />
            <div class="text-center relative z-5 w-full">
              <h2 class="text-[15px] text-white font-bold title-font mb-2"> DSP Mutual Fund Account Apply  </h2>
              
              <a href="https://www.dspim.com/" >  <Button className="bg-green-500 mt-3" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700  hover:text-white  focus:text-white">
         <a href="https://www.dspim.com/"  target_blank className="text-[9px] mr-[2px]" > Apply Now  </a>

  <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix>
        </ListItem> 
        </Button></a>
            </div>
          </div>
        </div> 


        <div class="px-2 w-1/2">
        <div class="flex flex-wrap w-full bg-gradient-to-t from-black  bg-yellow-400 bg-opacity-30 sm:py-24 py-16 sm:px-10 px-6 relative">
          <img
          class="w-full object-cover h-full object-center block opacity-20 absolute inset-0" src="https://ik.imagekit.io/hickter/Hickter_Projects/Money_Vision/Untitled%20(1).webp?updatedAt=1714832627947"
          alt="pic"
        />
            <div class="text-center relative z-5 w-full">
              <h2 class="text-[15px] text-white font-bold title-font mb-2"> Bajaj Finserv Mutual Fund  </h2>
              
              <a href="https://invest.bajajamc.com/mutual-fund/pan-entry/#?eyJkaXN0cmlidXRvcmNvZGUiOiJBUk4tMTA0Mzc0IiwiZXVpbm5vIjoiRTEzNDgzOSIsImFkZF9zY2hlbWUiOiJObyIsImFkZF9zdWJfYnJva2VyIjpudWxsLCJyZWdfZGVjbGFyYXRpb24iOiIiLCJzdWJfYXJuX2NvZGUiOiIiLCJzdWJfYnJva2VyX2NvZGUiOiIifQ==" >  <Button className="bg-green-500 mt-3" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700  hover:text-white  focus:text-white">
         <a href="https://invest.bajajamc.com/mutual-fund/pan-entry/#?eyJkaXN0cmlidXRvcmNvZGUiOiJBUk4tMTA0Mzc0IiwiZXVpbm5vIjoiRTEzNDgzOSIsImFkZF9zY2hlbWUiOiJObyIsImFkZF9zdWJfYnJva2VyIjpudWxsLCJyZWdfZGVjbGFyYXRpb24iOiIiLCJzdWJfYXJuX2NvZGUiOiIiLCJzdWJfYnJva2VyX2NvZGUiOiIifQ=="  target_blank className="text-[9px] mr-[2px]" > Apply Now  </a>

  <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix>
        </ListItem> 
        </Button></a>
            </div>
          </div>
        </div> 


     

      </div>
    </div>
   
</section>
 
   </>
  )
}
 
