/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
import Faqsection from '../views/Home/Faqsection'

export default function Faq() {
  return (
   <>
   <Navbarsection/>
<Faqsection/>
   <Footersection/>
   </>
  )
}
