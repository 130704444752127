/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-undef */
import { Card } from 'flowbite-react';
import { FaExternalLinkAlt } from "react-icons/fa";
export default function Feature() {
  return (
   <> 
 <section class="text-gray-600 body-font">
  <div class="container px-5 py-2 mx-auto">
    <div class="flex flex-wrap -m-4 text-center">
 
 {/* Pancard */}

      <div class="p-4 sm:w-1/4 w-1/2">
     <a href='/Quickapply' >  <Card className=" " imgSrc="https://ik.imagekit.io/moneyvision/Banner/1.png?updatedAt=1714489827105" >  
          <h6 className="mb-0 text-[9px] font-bold leading-5 tracking-wider uppercase">
          <FaExternalLinkAlt />  PAN Card 
          </h6> 
          </Card></a>
      </div>

 {/* Stock Market */}

      <div class="p-4 sm:w-1/4 w-1/2">
     <a href='/Quickapply' >  <Card className=" " imgSrc="https://ik.imagekit.io/moneyvision/Banner/2.png?updatedAt=1714489826589" >  
          <h6 className="mb-0 text-[9px] font-bold leading-5 tracking-wider uppercase">
          <FaExternalLinkAlt /> StockMarket 
          </h6> 
          </Card></a>
      </div>


      {/* Loan */}

      <div class="p-4 sm:w-1/4 w-1/2">
     <a href='/Quickapply' >  <Card className=" " imgSrc="https://ik.imagekit.io/moneyvision/Banner/8.png?updatedAt=1714489826707" >  
          <h6 className="mb-0 text-[9px] font-bold leading-5 tracking-wider uppercase">
          <FaExternalLinkAlt /> Loan  
          </h6> 
          </Card></a>
      </div>

       {/* Insurance */}

       <div class="p-4 sm:w-1/4 w-1/2">
     <a href='/Quickapply' >  <Card className=" " imgSrc="https://ik.imagekit.io/moneyvision/Banner/5.png?updatedAt=1714489826537" >  
          <h6 className="mb-0 text-[9px] font-bold leading-5 tracking-wider uppercase">
          <FaExternalLinkAlt />  Insurance 
          </h6> 
          </Card></a>
      </div>


{/* Tax Filling  */}

<div class="p-4 sm:w-1/4 w-1/2">
     <a href='/Quickapply' >  <Card className=" " imgSrc="https://ik.imagekit.io/moneyvision/Banner/6.png?updatedAt=1714489825913" >  
          <h6 className="mb-0 text-[9px] font-bold leading-5 tracking-wider uppercase">
          <FaExternalLinkAlt />   Tax Filling 
          </h6> 
          </Card></a>
      </div>

      {/* IPO   */}

<div class="p-4 sm:w-1/4 w-1/2">
     <a href='/Quickapply' >  <Card className=" " imgSrc="https://ik.imagekit.io/moneyvision/Banner/3.png?updatedAt=1714489826774" >  
          <h6 className="mb-0 text-[9px] font-bold leading-5 tracking-wider uppercase">
          <FaExternalLinkAlt />  IPO  
          </h6> 
          </Card></a>
      </div>


 {/* Bonds   */}

 <div class="p-4 sm:w-1/4 w-1/2">
     <a href='/Quickapply' >  <Card className=" " imgSrc="https://ik.imagekit.io/moneyvision/Banner/4.png?updatedAt=1714489826699" >  
          <h6 className="mb-0 text-[9px] font-bold leading-5 tracking-wider uppercase">
          <FaExternalLinkAlt />  Bonds  
          </h6> 
          </Card></a>
      </div>

 {/* Sip   */}

 <div class="p-4 sm:w-1/4 w-1/2">
     <a href='/Quickapply' >  <Card className=" " imgSrc="https://ik.imagekit.io/moneyvision/Banner/9.png?updatedAt=1714489826583" >  
          <h6 className="mb-0 text-[9px] font-bold leading-5 tracking-wider uppercase">
          <FaExternalLinkAlt />  SIP Investments  
          </h6> 
          </Card></a>
      </div>

       
       
    </div>
  </div>
</section>

 
  </>
  )
}
  