/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'


export default function Blog2() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-center  font-bold lg:text-[19px]  sm:text-3xl justify-self-auto " > Why To Choose Money Vision  ? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            Money Vision is a reputable choice for financial services due to several compelling reasons:

Expertise and Experience: Money Vision boasts a team of seasoned professionals with extensive knowledge in finance, investment, and advisory services. Their expertise ensures reliable guidance and informed decision-making.
<br></br>
<b> Comprehensive Services: </b>Money Vision offers a wide array of financial services, including SIP investments, stock market insights, financial advisory, and investment management. Whether you’re a beginner or an experienced investor, they cater to diverse needs.
<br></br>
<b> Customized Solutions: </b>Recognizing that each individual’s financial situation is unique, Money Vision tailors solutions to match specific goals, risk tolerance, and time horizons. Personalized advice ensures optimal outcomes. </p> 
          </div>
          
        </div>
        <div>
        <div className='player-wrapper'>
        <img src="https://ik.imagekit.io/moneyvision/Banner/blog/4.webp?updatedAt=1714551831894" className="h-210 w-full 
    rounded --300" alt="" /> 
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
