/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'
import ReactPlayer from 'react-player' 

export default function Blog1() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-center  font-bold lg:text-[19px]  sm:text-3xl justify-self-auto " > What is Money Vision ? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            Money Vision offers a range of financial services to help you achieve your financial goals. Here are some of their offerings:<br></br>

<b> SIP Investments: </b> Start your first investment for your future. SIP (Systematic Investment Plan) allows you to invest regularly in mutual funds, helping you build wealth over time1.
<br></br>
<b> Start Saving:</b> Begin your journey towards financial security by saving consistently. Money Vision can guide you on effective saving strategies.
<br></br>
<b> Stock Market: </b>If you’re interested in real investments, Money Vision can help you navigate the stock market. Investing wisely can lead to financial freedom.
<br></br>
<b>Financial Advisory: </b>Money Vision provides expert advice on financial matters, including investment decisions and risk management.
 

            </p>
          </div>
          
        </div>
        <div>
        <div className='mr-3 player-wrapper'>
        <div className='player-wrapper'>
        <img src="https://ik.imagekit.io/moneyvision/Banner/blog/2.webp?updatedAt=1714551831899" className="h-210 w-full 
    rounded --300" alt="" /> 
        </div>
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
