/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Footer } from 'flowbite-react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter,BsLinkedin,BsYoutube,BsSkype,BsPinterest,BsTelegram } from 'react-icons/bs';

function Footersection() {
  return (
   <>
 
    <Footer className='bg-gray-240 bg-opacity-40' >
      <div className="w-full">
        <div className="grid w-full grid-cols-2 gap-8 px-6 py-8 md:grid-cols-4">
          <div>
            <Footer.Title title="Company" />
            <Footer.LinkGroup col>
              <Footer.Link href="/About">About</Footer.Link>
              <Footer.Link href="/Jobs">Careers</Footer.Link>
              
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title title="help center" />
            <Footer.LinkGroup col>
              <Footer.Link href="mailto:moneyvision.ind@gmial.com">Support </Footer.Link> 
              <Footer.Link href="/Contact">Contact Us</Footer.Link>
              
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title title="legal" />
            <Footer.LinkGroup col>
              <Footer.Link href="/Privacy">Privacy Policy</Footer.Link> 
              <Footer.Link href="/Terms">Terms &amp; Conditions</Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title title="download" />
            <Footer.LinkGroup col>
              <Footer.Link href="/">iOS</Footer.Link>
              <Footer.Link href="https://play.google.com/store/apps/details?id=com.moneyvisionappofficial.com">Android</Footer.Link>  
            </Footer.LinkGroup>
          </div>
        </div>
        <div className="w-full bg-green-600 bg-opacity-100 px-4 py-6 sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright className='text-white' href="#"  target="_blank" by="Money Vision " year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon className='text-white' href="https://www.facebook.com/" icon={BsFacebook} />
            <Footer.Icon className='text-white' href="https://www.instagram.com/moneyvision.india/" icon={BsInstagram} />
            <Footer.Icon className='text-white' href="https://twitter.com/moneyvisionind" icon={BsTwitter} />
            <Footer.Icon className='text-white' href="https://www.youtube.com/channel/UCO-VI15nV_dOtk47-LCSosw" icon={BsYoutube} />
            <Footer.Icon className='text-white' href="https://in.linkedin.com/company/" icon={BsLinkedin} />
            <Footer.Icon className='text-white' href="https://t.me/" icon={BsTelegram} />
            <Footer.Icon className='text-white' href="https://in.pinterest.com/monevision_india/" icon={BsPinterest} />
            
          </div>
        </div>
      </div>
    </Footer>
  

   </>
  )
}

export default Footersection