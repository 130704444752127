// eslint-disable-next-line no-unused-vars
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
import Ourservice from '../views/Home/Ourservice'

export default function Services() {
  return (
    <>
<Navbarsection/>
<Ourservice/>
<Footersection/>
    </>
  )
}
