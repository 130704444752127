/* eslint-disable react/no-unknown-property */
import { Accordion } from 'flowbite-react';

export default function Faqsection() {
  return (
   <>
   <br></br>
   <div class="mx-auto">
      <div  class="mb-[2px] text-gray-600 body-font  ">
      <h3 class="text-center text-2xl font-bold sm:text-3xl"> FAQ  </h3>
        </div>
      </div>

  <Accordion className="p-12" >
      <Accordion.Panel >
        <Accordion.Title className=" text-orange-700" >What Money Vision ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Money Vision Provide all Financial Products to users . So they can be Financial free in thier life. 
          </p>
           
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className=" text-orange-700" > Why To Choose Money Vision ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            
          Money Vision India:
About Money Vision India: Gives you financial literacy and providing financial services for individuals and businesses. With over 5,000 clients across the globe, they aim to empower generations through financial education and ease life through their services.
          </p>
        
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>

        <Accordion.Title className=" text-orange-700" >  How To Apply Services On Money Vision ?  </Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Just Visit <a className='font-bold  text-green-500' href='/Quickapply'>
            Apply Now : QuickApply   </a>Fill The Form & Get Update Soon . 
         
          </p>
            
           
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>

<Accordion.Title className=" text-orange-700" >  How To Start SIP With Money Vision  ?  </Accordion.Title>
<Accordion.Content>
  <p className="mb-2 text-gray-500 dark:text-gray-400">
  Just Visit <a className='font-bold text-[16px] text-green-500' href='/Startsip'>
    Apply : Start SIP  </a>Fill The Form & Get Update Soon . 
 
  </p>
    
   
</Accordion.Content>
</Accordion.Panel>
 


    </Accordion>
   </>
  )
}
 