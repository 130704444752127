/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import React, { Component } from 'react'
// eslint-disable-next-line no-unused-vars
import ReactPlayer from 'react-player' 


export default function Aboutusection() {
  return (
  <> 
  <section>
  <div className="mx-auto max-w-screen-xl px-4 py-2 sm:px-6  lg:px-3 lg:py-3 lg:mb-3">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div className="relative h-64 overflow-hidden rounded-lg sm:h-8 lg:order-last lg:h-full">
     
      <div className='mr-5 player-wrapper'>
      <div className='player-wrapper'>
        <img src="https://ik.imagekit.io/moneyvision/Banner/blog/2.webp?updatedAt=1714551831899" className="h-210 w-full 
    rounded --300" alt="" /> 
        </div>
        </div>

      </div>

      <div className="ml-6 mt-4 ">
        <h2 className="text-[18px] font-bold sm:text-4xl"> About <a className="text-green-500" > Money Vision </a> </h2>

        <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            Money Vision offers a range of financial services to help you achieve your financial goals. Here are some of their offerings:<br></br>

<b> SIP Investments: </b> Start your first investment for your future. SIP (Systematic Investment Plan) allows you to invest regularly in mutual funds, helping you build wealth over time1.
<br></br>
<b> Start Saving:</b> Begin your journey towards financial security by saving consistently. Money Vision can guide you on effective saving strategies.
<br></br>
<b> Stock Market: </b>If you’re interested in real investments, Money Vision can help you navigate the stock market. Investing wisely can lead to financial freedom.
<br></br>
<b>Financial Advisory: </b>Money Vision provides expert advice on financial matters, including investment decisions and risk management.
 

            </p>
      
      </div>
    </div><br></br>
  </div>
</section>
  </>
  )
}

 